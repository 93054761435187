
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom'
const styles = (theme) => ({
    ...theme.customStyle,
    footerLink: {        
        fontSize: 14,
        fontWeight: 'bold',
        color:'#0b002b',
        textAlign: 'center',        
        margin: '0px auto',
        '& a': {            
            margin: '0px 0.5em',
            textDecoration: 'none',
            color: 'inherit',
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
            },
        },
    },    
})

function Footer(props) {
    const { classes } = props
    return (
        <div className="footer-container">        
            <div className={classes.footerLink}>
                <Link to='/terms'>Terms of Service</Link>
                <Link to='/privacy'>Privacy Policy</Link>
                {/* <a href="/terms"></a> */}
                &copy;
                <a
                    href="http://www.aptitudemedical.com"
                    rel="noreferrer"
                    target="_blank"
                >
                Aptitude Clinical Diagnostics
                </a>
            </div>
                     
          
        </div>
    )
}

export default withStyles(styles)(Footer)
