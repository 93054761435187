import {
    SET_ERRORS,
    CLEAR_ERRORS,
    LOADING_UI,
    STOP_LOADING_UI,
    SET_MSG,
    CLEAR_MSG,
    CLEAR_UI,
    RESET_ALL_STATE
} from '../types'

const initialState = {
    loading: false,
    errors: {}, 
/**
 * Errors types:
 * 
 */
    msg: {},
}

export default function uiReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_ALL_STATE:
            return initialState
        case SET_MSG:
            return { ...state, loading: false, msg: action.payload }
        case CLEAR_MSG:
            return {...state,loading:false,msg:{}}
        case  CLEAR_UI:
            return {loading:false,errors:{},msg:{}}
        case SET_ERRORS:
            return { ...state, loading: false, errors: action.payload }
        case CLEAR_ERRORS:
            return { ...state, loading: false, errors: {} }
        case LOADING_UI:
            return { ...state, loading: true }
        case STOP_LOADING_UI:
            return { ...state, loading: false }
        default:
            return state
    }
}
