// user reducer types
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
export const SET_USER = 'SET_USER'
export const LOADING_USER = 'LOADING_USER'
export const SET_CONTACT_LETTER = 'SET_CONTACT_LETTER'

// UI reducer types
export const SET_ERRORS = 'SET_ERRORS'
export const LOADING_UI = 'LOADING_UI'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const STOP_LOADING_UI = 'STOP_LOADING_UI'
export const SET_MSG = 'SET_MSG'
export const CLEAR_MSG = 'CLEAR_MSG'
export const CLEAR_UI = 'CLEAR_UI'
// data reducer types
export const SET_TO_APPROVE = 'SET_TO_APPROVE'
export const APPROVE_RESULT = 'APPROVE_RESULT'
export const SET_PDF_DATA = 'SET_PDF_DATA'
export const SET_BOOK_INFO = 'SET_BOOK_INFO'
export const SET_BATCH_RESULT_INFO = 'SET_BATCH_RESULT_INFO'
export const SET_BATCH_RESULT = 'SET_BATCH_RESULT'
export const CLEAR_BATCH_INFO = 'CLEAR_BATCH_INFO'
export const SET_BATCH_NOTE = 'SET_BATCH_NOTE'
export const SET_RECEPTION_LIST = 'SET_RECEPTION_LIST'
export const SET_RECEPTION_CALENDAR = 'SET_RECEPTION_CALENDAR'
export const UPLOAD_INSURANCE_FORM = 'UPLOAD_INSURANCE_FORM'
export const SET_INSURANCE_FORM_RECORDS = 'SET_INSURANCE_FORM_RECORDS'
export const SET_BOOK_LOCATION = 'SET_BOOK_LOCATION'
//booking reducer types
export const CLEAR_BOOK_INFO = 'CLEAR_BOOK_INFO'
export const SET_PATIENT_INFO  = 'SET_PATIENT_INFO'
export const SET_BOOKING_FIELDS = "SET_BOOKING_FIELDS"
export const SET_CORP_BOOKING_URL = 'SET_CORP_BOOKING_URL'
export const SET_BOOK_TO_CHANGE = 'SET_BOOK_TO_CHANGE'
export const SET_COUPON = 'SET_COUPON'
export const SET_TOKEN_BOOKING = 'SET_TOKEN_BOOKING'

//

export const RESET_ALL_STATE = 'RESET_ALL_STATE'