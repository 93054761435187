import dayjs from 'dayjs'

export const defaultBookingInfo = {
    booking: {
        activeStep: 0,
        paid: false,
        batchSubmitType:'', // use this to determine if the user is submitting a tube in batch or send patient a link.
        confNo:"",
        qrCode:"",  
        coupon:{
            coupon:'', // the code
            discount:'',//discount amount.
        },
        tubeID:'',
        datetime: { date: dayjs().format('YYYY-MM-DD'), time: '' },
        location:'mainLocation', // the location user selected. now defaults to 'mainLocation'
        bookInfo: {}, // for holding available dates
        /**
         * THis bookInfo is holding the entier bookingSchedule document from firebase.
         */
        stripe: {            
            clientSecret:null,
            docId:null,
            error:null,
            loading:false,            
        }, // for holding stripe info
        /* 
        {
            book:{"date ISO string":[]}
        }        
        */
    },
    patientInfo: {
        firstName: '',
        lastName: '',
        mdName: '',
        email: '',
        dob: '',
        address: '', // need to be street address only.
        terms: false,
        consent:false,
        gender: '',
        race: '',
        zip: '',
        // new fields TO add:
        patient_city: '',
        patient_state: '',
        patient_ethnicity: '',
        patient_phone:"",
        
        /*
        health questionnaire fields all stored in this field:
        healthForm:{}
        */

    },
}
