import {Helmet} from 'react-helmet'

function LoadingPage() {
    return (
        <div className="container">
            <Helmet>
                <title>Loading...</title>
            </Helmet>
            <div className="info-content">
                <div style={{textAlign:'center'}}>
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                </div>
                {/* <Typography
                    variant="button"
                    style={{ fontSize: 24 }}
                    color="textSecondary"
                >
                    Loading...
                </Typography> */}
                
            </div>
        </div>
    )
}

export default LoadingPage
