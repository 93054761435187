
import {
    DialogTitle,
    DialogActions,
    DialogContent,
} from '../components/DialogComponents'
import Dialog from '@mui/material/Dialog'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'

const ConfirmDialog = ({
    handleClose,
    open,
    handleConfirm,
    title,
    children,
    confirmText,
    cancelText,
    confirmColor='primary',
    cancelColor='primary',
    disableConfirm=false,
    ...rest
}) => {
    return (
        <Dialog onClose={handleClose} open={open} {...rest}>
            <DialogTitle onClose={handleClose}>{title}</DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions style={{justifyContent:'space-around'}}>
                <Button onClick={handleConfirm} color={confirmColor} disabled={disableConfirm}>
                    {confirmText || 'Confirm'}
                </Button>
                <Button autoFocus onClick={handleClose} color={cancelColor}>
                    {cancelText || 'Cancel'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmDialog.porpTypes = {
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.any,
    children: PropTypes.any,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
}

export default ConfirmDialog
