import {
    SET_USER,
    SET_ERRORS,
    CLEAR_ERRORS,
    SET_UNAUTHENTICATED,
    LOADING_USER,
    SET_CONTACT_LETTER,    
    RESET_ALL_STATE,    
} from '../types'
import axios from 'axios'
import {queryClient} from '../../App'

let refreshTokenTimeout = null;
const MAX_TOKEN_AGE = 30 * 60 * 1000


const reFreshUserToken = () => {
    axios
        .get('/user/refreshToken')
        .then((res) => {            
            autoRefreshToken(res.data.token,Date.now())                     
        })
        .catch((err) => {            
            
        })
}

/**
 * 
 * @param {string} token the token to store in local storage
 * @param {number} timeStamp the time the token is generated
 */
const autoRefreshToken = (token,timeStamp) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`  
    localStorage.setItem('session-token', JSON.stringify({token,timeStamp}))    
    if (Date.now() - timeStamp > MAX_TOKEN_AGE) {        
        return reFreshUserToken()
    } else {
        refreshTokenTimeout = setTimeout(() => {
            reFreshUserToken()
        },MAX_TOKEN_AGE - (Date.now() - timeStamp))
    }
}

/**
 * 
 * @param {{email:string,password:string}} userData 
 * @param {*} history 
 * @param {*} nextUrl 
 * @returns 
 */
export const loginUser = (userData, history, nextUrl) => (dispatch) => {
    dispatch({ type: LOADING_USER })
    axios
        .post('/user/login', userData)
        .then((res) => {
            if (res.data.token) {                
                autoRefreshToken(res.data.token,Date.now())                            
                dispatch({type: SET_USER,
                    payload: res.data.user,
                })
            }
           
            if (res.data.requireMFAToken) {                 
                return dispatch({type:SET_USER,payload: {requireMFAToken:true,authenticated:false}})
            }            
            // if (nextUrl) {history.push(nextUrl)}
            dispatch({ type: CLEAR_ERRORS })
            // after ~ 30min automatically login again
            // BUGFIX: this won't work because of MFA.
           
        })
        .catch((err) => {
            dispatch({
                type: SET_UNAUTHENTICATED,
            })
            dispatch({
                type: SET_ERRORS,
                payload: err.response
                    ? err.response.data
                    : { error: 'Server no response.' },
            })
        })
}



 

/**
 * If user have session storage that have valid token, directly login user.
 * @returns 
 */
export const loginUserFromLastSession = () => (dispatch) => {
    // first check if there is toekn in session storage.        
    const {token,timeStamp} = JSON.parse(localStorage.getItem('session-token') || '{}')
    if (token && timeStamp && Date.now() - timeStamp < 55*60*1000) {        
        autoRefreshToken(token,timeStamp)
    } else {
        // don't have valid session token, return
        return
    }
    axios
        .get('/user')
        .then((res) => {            
            dispatch({
                type: SET_USER,
                payload: res.data,
            })            
        })        
        .catch((err) => {
            // console.log(err)
        })
}

export const logoutUser = () => (dispatch) => {       
    clearTimeout(refreshTokenTimeout)
    localStorage.removeItem('session-token')            
    delete axios.defaults.headers.common['Authorization']        
    dispatch({ type: RESET_ALL_STATE })   
    queryClient.clear()        
}

export const sendContactLetter = (form) => (dispatch) => {
    dispatch({
        type: SET_CONTACT_LETTER,
        payload: { loading: true, sent: false },
    })
    axios
        .post('/user/inquiryLetter', form)
        .then((res) => {
            dispatch({
                type: SET_CONTACT_LETTER,
                payload: { loading: false, sent: true },
            })
        })
        .catch((err) => {            
            dispatch({ type: SET_CONTACT_LETTER, payload: { loading: false } })
        })
}

 
/**
 * Temporaryly change user properties in memory
 * @param {object} userProps user properties
 * @returns 
 */
export const changeUserProps = (userProps) => (dispatch) => {
    dispatch({ type: SET_USER, payload: userProps })
}
