import {
    SET_TO_APPROVE,
    APPROVE_RESULT,
    SET_PDF_DATA,
    SET_BOOK_INFO,
    CLEAR_BOOK_INFO,
    SET_PATIENT_INFO,
    SET_BOOKING_FIELDS,
    SET_BATCH_RESULT_INFO,
    SET_BATCH_RESULT,
    CLEAR_BATCH_INFO,
    SET_BATCH_NOTE,
    SET_CORP_BOOKING_URL,
    SET_BOOK_TO_CHANGE,
    SET_COUPON,
    SET_RECEPTION_LIST,
    SET_RECEPTION_CALENDAR,
    UPLOAD_INSURANCE_FORM,
    SET_INSURANCE_FORM_RECORDS,
    SET_TOKEN_BOOKING,
    SET_BOOK_LOCATION,    
    RESET_ALL_STATE
} from '../types'
import dayjs from 'dayjs'
import { defaultBookingInfo } from './defaultStates'

const initialState = {
    toApprove: [], // list of diagnose documents to be approved
    toApprovePatientDetails: {}, // {docID:{patient info}}
    reportPdf: {},
    batchResultInfo: [],
    batchResults: {},
    corpBookingUrl: '',
    ...defaultBookingInfo,
    bookToChange: {},
    receptionCalendarTimeStamp: null,
    receptionCalendar: {},
    receptionPatientList: {}, //{'2010-04-12':[patient docs]}
    insuranceForm: {
        loading: false,
        uploaded: false,
        error: null,
    },
    insuranceFormRecords: {
        loading: false,
        records: [],  
        error: null,    
    },
    tokenBooking: {
        loading: true, // trach whetehr all paitient records are downloaded.
        ready:false,
        error:false,
        patientRecords:{},
        hasNext:true,
        lastUpdateAt:'', // time stamp of the last patient record copy
        orgId: '',
    },    
}

const mapToApprove = (item) => {
    // convert the toApprove data to display object and parse date.
    let obj = { ...item.details, id: item.docID }
    const fields = ['testEnd', 'testStart', 'collectAt']
    fields.forEach((i) => (obj[i] = dayjs(obj[i]).format('YYYY/MM/DD, HH:mm')))
    return obj
}

export default function dataReducer(state = initialState, action) {
    const payload = action.payload
    switch (action.type) {
        case RESET_ALL_STATE:
            return initialState
        case SET_TOKEN_BOOKING:
            return {...state, tokenBooking: {...state.tokenBooking, ...payload}}
        case SET_INSURANCE_FORM_RECORDS:
            return {...state, insuranceFormRecords: {...state.insuranceFormRecords, ...payload}}
        case UPLOAD_INSURANCE_FORM:
            return {...state, insuranceForm: {...state.insuranceForm, ...payload}}
        case SET_RECEPTION_CALENDAR:
            return {
                ...state,
                receptionCalendar: payload,
                receptionCalendarTimeStamp: new Date(),
            }
        case SET_RECEPTION_LIST:
            const receptionPatientList = {}
            payload.forEach((doc) => {
                const date = doc.date
                if (date in receptionPatientList) {
                    receptionPatientList[date].push(doc)
                } else {
                    receptionPatientList[date] = [doc]
                }
            })
            return {
                ...state,
                receptionPatientList,
                receptionCalendarTimeStamp: new Date(),
            }
        case SET_COUPON:
            return { ...state, booking: { ...state.booking, coupon: payload } }
        case SET_BOOK_TO_CHANGE:
            return { ...state, bookToChange: payload }
        case SET_TO_APPROVE:
            // transform the to approve data by parsing the dates.
            let toApprove = payload.map(mapToApprove)
            let toApprovePatientDetails = {}
            payload.forEach((item) => {
                toApprovePatientDetails[item.docID] = item.patientInfo
            })
            return { ...state, toApprove, toApprovePatientDetails }
        case SET_CORP_BOOKING_URL:
            return { ...state, corpBookingUrl: payload.url }
        case SET_BATCH_RESULT_INFO:
            return { ...state, batchResultInfo: payload }
        case CLEAR_BATCH_INFO:
            return { ...state, batchResultInfo: [], batchResults: {} }
        case SET_BATCH_RESULT:
            return {
                ...state,
                batchResults: { ...state.batchResults, ...payload },
            }
        case SET_BATCH_NOTE:
            const { id, note } = payload
            const newInfo = state.batchResultInfo.map((info) => {
                if (info.batchID === id) return { ...info, note }
                else return info
            })
            return { ...state, batchResultInfo: newInfo }
        case APPROVE_RESULT:
            return {
                ...state,
                toApprove: state.toApprove.filter(
                    (s) => !payload.includes(s.id)
                ),
            }
        case SET_PDF_DATA:
            return { ...state, reportPdf: payload }

        case SET_BOOK_INFO:         
            return {
                ...state,
                booking: {
                    ...state.booking,                    
                    bookInfo:payload,
                },
            }
        case SET_BOOK_LOCATION:
            return {...state,booking:{...state.booking,location:payload.location}}
        case SET_PATIENT_INFO:
            return {
                ...state,
                patientInfo: { ...state.patientInfo, ...payload },
            }
        case CLEAR_BOOK_INFO:
            return {
                ...state,
                ...defaultBookingInfo,
            }
        case SET_BOOKING_FIELDS:
            return {
                ...state,
                booking: {
                    ...state.booking,
                    ...payload,
                },
            }      
        default:
            return state
    }
}
