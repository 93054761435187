import {Component} from 'react'
import Button from '@mui/material/Button'
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from '@mui/material/Typography'


class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
        // console.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <ErrorPage/>
      }
  
      return this.props.children; 
    }
  }


export function ErrorPage() {
    return (
        <div className='container'>            
        <div className='info-content' >
      
            <Typography variant='button' style={{fontSize:130,letterSpacing:15,fontWeight:600}}>5
            <Typography style={{display:'inline',fontSize:130,letterSpacing:15,fontWeight:600}} color='secondary'>0</Typography>
            0</Typography>

            <Typography variant='h2' style={{fontSize:30}}>An error has occurred while loading the page.</Typography>
            <Typography variant='subtitle1' style={{fontSize:20}}>Please refresh the page and try again.</Typography>

            <div style={{margin:'10px'}}>
            <Button 
            onClick = {() => window.location.reload()}            
            color='primary'
            style={{ textTransform:'none'}}
            >
              <RefreshIcon style={{fontSize:32,marginRight:'10px'}}/>
              <Typography style={{fontSize:32}}>Refresh</Typography>              
              </Button>
            </div>
            <Typography variant='subtitle1' >If the error is still not resolved, please contact us.</Typography>
            {window.location.href}
        </div>  </div>
    )
}

export default ErrorBoundary