

export function PhoneIcon({size,fill,opacity}){    
    const style = {fill:fill||"#3c4459",opacity:opacity||0.73,}
    return (<svg height={size || 100} width={size || 100} viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">                        
        <path style={style} d="M61.25,29.2A1.16,1.16,0,0,1,62.42,28H86.54a1.16,1.16,0,0,1,1.17,1.16v.32a1.17,1.17,0,0,1-1.17,1.17H62.42a1.18,1.18,0,0,1-1.17-1.17ZM103.59,36v84.54H45.37V36ZM74.48,134.78a5.29,5.29,0,1,1,5.29-5.29A5.29,5.29,0,0,1,74.48,134.78ZM40.09,33.34V128.6c0,10.58,10.58,10.58,10.58,10.58H98.3s10.58,0,10.58-10.58V33.34c0-10.59-10.58-10.59-10.58-10.59H50.67S40.09,22.75,40.09,33.34Zm79.37,84.53V22.75c0-10.57-10.58-10.57-10.58-10.57H61.25s-6.67,0-9.42,5.29h57.05a7.56,7.56,0,0,1,2.93.75c1.57.8,2.36,2,2.36,4.53V127.12C116.82,125.79,119.46,123.15,119.46,117.87Z"/>
        <path style={style} d="M83.43,76.57l-8.75,8.74a2.6,2.6,0,0,1-3.65,0L65.72,80a2.75,2.75,0,1,1,3.89-3.9l3.25,3.25,6.66-6.66a2.76,2.76,0,0,1,3.91,3.89ZM74.57,61.44A17.15,17.15,0,1,0,91.72,78.59,17.19,17.19,0,0,0,74.57,61.44Zm0-2.17A19.32,19.32,0,1,0,93.88,78.59,19.35,19.35,0,0,0,74.57,59.27Zm0,40.5A21.19,21.19,0,1,1,95.75,78.59,21.2,21.2,0,0,1,74.57,99.77Z"/>                        
    </svg>)
}


export function CalendarIcon({size,fill,opacity}){    
    const style = {fill:fill||"#3c4459",opacity:opacity||0.73,}
    return (<svg height={size || 100} width={size || 100} viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">                        
       <path style={style} d="M41.27,14.43h9.21V29.94H87.33V14.43h9.22V29.94H111.9V56.3h-86V29.94H41.27Zm0,15.51v9.87a6.11,6.11,0,0,0-1.55,4.08,6.15,6.15,0,1,0,10.76-4.08V29.94Zm46.06,0v9.87a6.17,6.17,0,1,0,10.76,4.08,6.11,6.11,0,0,0-1.55-4.08V29.94ZM25.91,65.62h86V76.46a39.57,39.57,0,0,0-20,5.4V74.92H82.73v9.3h5.63A40.4,40.4,0,0,0,72,116.79H25.91Zm20,9.3v9.3h9.2v-9.3Zm18.41,0v9.3h9.23v-9.3Zm47.62,10.84a31,31,0,1,1-30.7,31A30.87,30.87,0,0,1,111.91,85.76Zm-66,7.76v9.32h9.2V93.52Zm18.41,0v9.32h9.23V93.52Zm41.48,7.75V123h21.5v-9.32H115V101.27Z"/>
    </svg>)
}


export function TubeIcon({size,fill,opacity,...rest}){     
    return (<svg height={size || 25} width={size || 25} viewBox="15 10 130 133" xmlns="http://www.w3.org/2000/svg">                             
       <path style={{fill:fill||"#fff", opacity:opacity||1,}} d="M98.79,63.88v-12H60.84v8.29h0v66.35c0,9.17,8.51,16.59,19,16.59s19-7.42,19-16.59V64.67A5,5,0,0,1,98.79,63.88Zm-9.41,62.65c0,4.58-4.26,8.3-9.51,8.3s-9.51-3.72-9.51-8.3v-7.37l19-5.54Zm0-21.65-19,5.53v-15h7.13c2.63,0,4.76-1.85,4.76-4.15s-2.13-4.14-4.76-4.14H70.36v-8.3h7.13c2.63,0,4.76-1.85,4.76-4.14s-2.13-4.15-4.76-4.15H70.36V60.18h19Z"/>
          <path style={{fill:'none',stroke:fill||"#fff",strokeMiterlimit:10,strokeWidth:10,opacity:opacity||1,}} d="M119.06,19c0,18-17.55,32.53-39.2,32.53S40.65,36.94,40.65,19C40.65,16.82,119.06,16.63,119.06,19Z"/>   
    </svg>)
}



export function CompassIcon({size,fill,opacity}){    
    const style = {fill:fill||"#3c4459",opacity:opacity||0.73,}
    return (<svg height={size || 100} width={size || 100} viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">                        
    <path style={style} d="M110.56,66.59a2.42,2.42,0,0,0-2.8,1.93A30.87,30.87,0,0,1,89.35,91.39l-6-16.85a12.14,12.14,0,0,0-4.13-22.38V43.25a2.41,2.41,0,0,0-4.82,0v8.91A12.15,12.15,0,0,0,70.5,74.67L53.67,121.73a2.36,2.36,0,0,0,1.48,3,2.74,2.74,0,0,0,.79.14,2.41,2.41,0,0,0,2.27-1.6L75,76.23a10.1,10.1,0,0,0,1.76.16,13.93,13.93,0,0,0,2-.18l6,16.7a31.3,31.3,0,0,1-13.28.54,2.38,2.38,0,1,0-.85,4.68,38.17,38.17,0,0,0,6.36.53,36.49,36.49,0,0,0,9.38-1.25l9.26,25.9a2.42,2.42,0,0,0,2.27,1.58,2.33,2.33,0,0,0,.8-.14,2.37,2.37,0,0,0,1.47-3L91,95.87a36.92,36.92,0,0,0,6.71-3.63,35.18,35.18,0,0,0,14.86-22.9A2.4,2.4,0,0,0,110.56,66.59Z"/>       
    </svg>)
}



export function FastIcon({size,fill,opacity}){    
    const style = {fill:fill||"#3c4459",opacity:opacity||0.73,}
    return (<svg height={size || 100} width={size || 100} viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">                        
       <path style={style} d="M83,58.81H116.6A6.29,6.29,0,0,1,121.36,61a6.05,6.05,0,0,1,1.43,5L118,96.28a6.18,6.18,0,0,1-6.19,5.21H78.16a6.31,6.31,0,0,1-4.77-2.16,6.08,6.08,0,0,1-1.43-5L76.78,64A6.21,6.21,0,0,1,83,58.81Zm0,5.77a.4.4,0,0,0-.39.33L77.75,95.26a.46.46,0,0,0,.09.32.43.43,0,0,0,.32.14h33.61a.42.42,0,0,0,.41-.33L117,65a.33.33,0,0,0-.09-.31.37.37,0,0,0-.3-.14h-12l-1.54,8.17a2,2,0,0,1-1.93,1.58H94.33a2,2,0,0,1-1.5-.7A1.87,1.87,0,0,1,92.41,72l1.41-7.46ZM58,65.73H70.31a2.89,2.89,0,1,1,0,5.77H58a2.89,2.89,0,1,1,0-5.77Zm-8.8,11.54H67.38a2.89,2.89,0,1,1,0,5.77H49.23a2.89,2.89,0,1,1,0-5.77ZM40.43,88.8h24a2.89,2.89,0,1,1,0,5.77h-24a2.89,2.89,0,1,1,0-5.77Z"/>

    </svg>)
}



export function ShieldIcon({size,fill,opacity}){    
    const style = {fill:fill||"#3c4459",opacity:opacity||0.73,}
    return (<svg height={size || 100} width={size || 100} viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">                        
        <path style={style} d="M73.14,96.39,58.53,82.81l4.9-5.34,9.41,8.66L92.21,65.62l5.21,4.92ZM47.25,51.71,46,82c-.61,14.5,19.76,32.08,28.27,32.44s30.29-15.35,30.9-30l1.27-30.25A59.11,59.11,0,0,1,90.7,49.56,89.54,89.54,0,0,1,77.4,41.9a88.89,88.89,0,0,1-14.1,6.51A62.18,62.18,0,0,1,47.25,51.71Zm26.87,68.34c-12.46-.53-34.8-20.59-34-38.65l1.5-35.89,3.54.05a52.78,52.78,0,0,0,16.34-2.77,74,74,0,0,0,14.11-6.72l2.14-1.48,2,1.65a71.77,71.77,0,0,0,13.5,7.89,49.34,49.34,0,0,0,16,4.12l3.53.25-1.51,35.9C110.53,102.45,86.57,120.57,74.12,120.05Z"/>
    </svg>)
}



export function HomeIcon({size,fill,opacity}){    
    const style = {fill:fill||"#fff",opacity:opacity||1,}
    return (<svg height={size || 24} width={size || 24} viewBox="51 51 50 50" xmlns="http://www.w3.org/2000/svg">                        
       <path style={style} d="M97.33,73.74C96.2,72.35,79.92,53,78.9,51.72a2.77,2.77,0,0,0-4,0c-1.39,1.74-17.58,21-18.39,22-1,1.19.27,2.4,2,2.4h2.61V91.22a3.18,3.18,0,0,0,3,3.36H73V82.26h7.9V94.58h9a3.18,3.18,0,0,0,3-3.36V76.09h2.57C97.24,76.09,98.16,74.75,97.33,73.74Z"/>
    </svg>)
}

export function ZoomQuestionIcon({size,fill,opacity}){    
    const style = {fill:fill||"#fff",opacity:opacity||1,}
    return (<svg height={size || 24} width={size || 24} viewBox="44 56 52 51" xmlns="http://www.w3.org/2000/svg">                        
       <path style={style} d="M74.76,57.25A19.47,19.47,0,0,1,88.53,90.49,19.11,19.11,0,0,1,65,93.42a.83.83,0,0,0-1,.13l-9.92,9.91c-2,2-4.74,2.46-6.49.7l-.3-.3c-1.77-1.75-1.29-4.51.69-6.5l9.93-9.94a.8.8,0,0,0,.11-1A19.09,19.09,0,0,1,61,63,19.4,19.4,0,0,1,74.76,57.25Zm0,5.08a14.39,14.39,0,1,0,10.17,4.21A14.34,14.34,0,0,0,74.76,62.33ZM74.7,66H75a6,6,0,0,1,6,6c0,2.4-1.38,4-2.49,5.24a5.34,5.34,0,0,0-1.39,2.3c-.3,1.21-1,1.6-2.27,1.6s-2.29-.35-2.29-1.6c0-2.44,1.4-4,2.52-5.31.84-1,1.37-1.58,1.37-2.23A1.48,1.48,0,0,0,75,70.52H74.7A1.47,1.47,0,0,0,73.23,72c0,1.26-1,1.61-2.29,1.61s-2.28-.35-2.28-1.61A6,6,0,0,1,74.7,66Zm-.81,16.63h1.86A1.24,1.24,0,0,1,77,83.83v1.88a1.24,1.24,0,0,1-1.24,1.23H73.89a1.24,1.24,0,0,1-1.24-1.23V83.83A1.24,1.24,0,0,1,73.89,82.59Z"/>
    </svg>)
}



export function PersonCallIcon({size,fill,opacity}){    
    const style = {fill:fill||"#fff",opacity:opacity||1,}
    return (<svg height={size || 24} width={size || 24} viewBox="55 55 50 50" xmlns="http://www.w3.org/2000/svg">                        
       <path style={style} d="M83.54,55.58a9.83,9.83,0,1,1-9.8,9.83A9.82,9.82,0,0,1,83.54,55.58ZM65.43,71a2.16,2.16,0,0,1,1.36.94l2.92,4.5a2.17,2.17,0,0,1-.65,3l-1.34.82a1.79,1.79,0,0,0-.35,2.4l3.38,5.26a1.85,1.85,0,0,0,2.34.64l1.22-.82a2.07,2.07,0,0,1,3,.64l2.92,4.5a2.17,2.17,0,0,1-.64,3,14.47,14.47,0,0,1-2.45,1.41c-4.2,1.81-8.06-1.7-12.73-9S58,76.34,61.48,73.07a16.25,16.25,0,0,1,2.33-1.76A2.13,2.13,0,0,1,65.43,71Zm14.66,6.67h7A12.62,12.62,0,0,1,99.64,90.31v10.12s-5.78,3.15-15.64,3.15h-.87c-10.86,0-15.64-3.15-15.64-3.15V97.21c2.16,2,4.55,3.39,7.23,3.39A7.92,7.92,0,0,0,78,100a15.87,15.87,0,0,0,3-1.7,5,5,0,0,0,1.46-7L79.51,86.8a5.13,5.13,0,0,0-4.27-2.33,4.88,4.88,0,0,0-2.79.88l-.12.05-2-3.1A12.67,12.67,0,0,1,80.09,77.69Z"/>
    </svg>)
}



export function ProfileIcon({size,fill,opacity}){    
    const style = {fill:fill||"#0b002b",opacity:opacity||1,}
    return (<svg height={size || 24} width={size || 24} viewBox="60 60 40 50" xmlns="http://www.w3.org/2000/svg">                        
       <path style={style} d="M97.23,91.72a1.49,1.49,0,0,0-1.5-1.48h-9a1.49,1.49,0,1,0,0,3h9A1.5,1.5,0,0,0,97.23,91.72Zm0-5.77a1.49,1.49,0,0,0-1.5-1.48h-9a1.49,1.49,0,1,0,0,3h9A1.5,1.5,0,0,0,97.23,86Zm0-5.76a1.5,1.5,0,0,0-1.5-1.49h-9a1.49,1.49,0,1,0,0,3h9A1.49,1.49,0,0,0,97.23,80.19Zm-1.5-4.28h-9a1.49,1.49,0,1,1,0-3h9a1.49,1.49,0,1,1,0,3ZM73.16,84.52c-3.42,0-10.25,1.93-10.25,5.78v1.41a1.5,1.5,0,0,0,1.5,1.49h17.5a1.49,1.49,0,0,0,1.5-1.49V90.3C83.41,86.45,76.58,84.52,73.16,84.52ZM68,78a5.1,5.1,0,0,1,5.22-5.07,5.08,5.08,0,1,1-1.73,9.88A5,5,0,0,1,68,78Zm32.68,20.9H59.46V68.24h41.25V98.91Zm.36-34.39h-42a3,3,0,0,0-3,3V99.63a3,3,0,0,0,3,3h12v3a3,3,0,0,0,3,3h12a3,3,0,0,0,3-3v-3h12a3,3,0,0,0,3-3V67.48A3,3,0,0,0,101.07,64.52Z"/>
    </svg>)
}



export function CallCircleIcon({size,fill,opacity}){    
    const style = {fill:fill|| '#0b002b',opacity:opacity||1,}
    return (<svg height={size || 24} width={size || 24} viewBox="55 55 50 50" xmlns="http://www.w3.org/2000/svg">                        
       <path style={style} d="M94.73,92c-.65,2.15-3.54,5.25-6.22,5.25l-.21-.1c-4-.22-9.43-4-14.47-10.07L72,84.86c-5-6.1-7.71-12.1-7.07-16,.43-2.79,4.5-5,6.86-5,1.18,0,1.6.53,1.71,1,1.29,2.36,3,6.43,3,7.93V73a3.39,3.39,0,0,1-1.5,1.5c-.75.43-1.18.75-1.29,1.5,0,.22.22,1.39,3.54,5.47l1.39,1.71c3.33,4,4.39,4.39,4.61,4.39.86.11,1.18-.21,1.82-.86a2.81,2.81,0,0,1,1.72-1.17H87c1.5.32,5.14,2.68,7.28,4.5C94.62,90.11,95.15,90.65,94.73,92ZM79.83,56.47a24,24,0,1,0,24,24A24,24,0,0,0,79.83,56.47Z"/>
    </svg>)
}

