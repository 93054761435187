import Mark from "./../assets/fonts/mark/MarkRegular.otf";

const theme = {
  palette: {
    primary: {
      // light: '#2B9B9F',
      // main: '#217679',
      // dark: '#134445',
      // contrastText: '#fff',
      light: "#2B9B9F",
      main: "#217679",
      dark: "#134445",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff6333",
      main: "#cc0000",
      dark: "#b22a00",
      contrastText: "#fff",
    },
  },
  spacing: 8,
  typography: {
    fontFamily: Mark,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
            font-family: 'Mark';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Mark'), local('MarkRegular'), url(${Mark}) format('otf');
        }
        `,
    },
  },
};

export default theme;
