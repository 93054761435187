import  { useRef, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

export const LoadImageCompressionScript = ({onLoad}) => {
    useEffect(() => {
        // only load this upon rendering
        if (!document.getElementById('browser-image-compression-script')) {
            const script = document.createElement('script')
            script.id = 'browser-image-compression-script'
            script.src = "https://cdn.jsdelivr.net/npm/browser-image-compression@1.0.17/dist/browser-image-compression.js"
            script.async = true
            script.onload = function () {
                if(onLoad) onLoad()            
            }
            document.head.appendChild(script)
        } else {
            if(onLoad) onLoad()
        }
    }, [onLoad])
    return null
}

export const handleImageCompression = async (file,compressionOptions,onCompressionProgress)=>{    
    let compressedFile = file
    // the imageCompression library is loaded from CDN
    const imageCompression = window.imageCompression
    if (file.type.match('image/(jpg|jpeg|png|bmp)')) {
        if (imageCompression) {
            // console.log('loaded compression')
            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
                onProgress: onCompressionProgress,
                ...(compressionOptions || {}),
            }
            try {
                compressedFile = await imageCompression(
                    file,
                    options
                )
            } catch (error) {
                // console.log(error);
                return
            }
            if (onCompressionProgress) {
                // after it's done, call onCompressionProgress(null)
                onCompressionProgress(null)
            }
        }
        let fileObject = {
            url: URL.createObjectURL(compressedFile),
            file: new File(
                [compressedFile],
                compressedFile.name,
                {
                    type: compressedFile.type,
                    lastModified: compressedFile.lastModified,
                    size: compressedFile.size,
                }
            ),
        }
        return fileObject
    }
    return null
}

const ImageUploadButton = ({
    onClick,
    children,
    onCompressionProgress,
    onFile,
    compressionOptions,
    component,
    ...rest
}) => {
    const imageRef = useRef(null)
    // loading of the image compression script
    const [loading,setLoading] = useState(true)    
    const Component = component || Button
    const disabled = loading || !!rest.disabled
    return (
        <>
            <LoadImageCompressionScript onLoad={()=>{setLoading(false)}}/>
            <Component
                onClick={() => {         
                    if (!disabled) {
                        imageRef.current.click()
                        onClick && onClick()
                    }
                }}
                {...rest}
                disabled={disabled}
            >
                {children}
                {loading && <CircularProgress size={12} style={{position:"absolute"}}/>}
            </Component>
            {/*Hidden input field for upload file.*/}
            <input
                ref={imageRef}
                type="file"
                onChange={async (e) => {                    
                    let file = e.target.files[0]
                    const compressedFile = await handleImageCompression(file,compressionOptions,onCompressionProgress)
                    if (compressedFile) {
                        onFile && onFile(compressedFile)
                    } else {

                    }
                    e.target.value = null                    
                }}
                hidden
                accept="image/*"
            ></input>
        </>
    )
}

export default ImageUploadButton
